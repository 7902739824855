import { WithTranslation, withTranslation } from "react-i18next";
import MenuHeader from "../../components/MenuHeader";
import Account from "../../components/Account";
import GalaxyMembers from "../../components/GalaxyMembers";
import InviteMembers from "../../components/InviteMembers";
import ImportMedia from "../../components/ImportMedia";
import ProfilePicture from "../../components/ProfilePicture";
import { createContext, ReactNode, useContext, useState } from "react";
import { UserContext } from "../../providers/UserProvider";
import style from './style.module.css';
import Action from "../../components/Action";
import UserLogo from '../../assets/user.svg?react';
import MembersLogo from '../../assets/members.svg?react';
import InviteLogo from '../../assets/addMember.svg?react';
import StatsLogo from '../../assets/statistics.svg?react';
import DeleteLogo from '../../assets/trashbin.svg?react';
import InspiLogo from '../../assets/inspiNotebook.svg?react';
import { Button } from "../../components/Button/index";
import Statistics from "../../components/Statistics";
import CreateGame from "../../components/CreateGame";
import { InspiNotebook } from "../../components/InspiNotebook";

interface NavigationContextProps {
    handleReturnClick: () => void;
    subscribe: (listener: () => void) => () => void;
}

const NavigationContext = createContext<NavigationContextProps | null>(null);

const NavigationProvider = ({ children }: { children: ReactNode }) => {
    const [listeners, setListeners] = useState<(() => void)[]>([]);

    const handleReturnClick = () => {
        // listeners.forEach(listener => listener()); // To notify all listeners
        if (listeners.length > 0) listeners[listeners.length - 1]();
    };

    const subscribe = (listener: () => void) => {
        setListeners(prevListeners => [...prevListeners, listener]);
        return () => {
            setListeners(prevListeners => prevListeners.filter(l => l !== listener));
        };
    };

    return (
        <NavigationContext.Provider value={{ handleReturnClick, subscribe }}>
            {children}
        </NavigationContext.Provider>
    );
};

export const useNavigation = () => {
    const context = useContext(NavigationContext);
    if (!context) {
        throw new Error('useNavigation must be used within a NavigationProvider');
    }
    return context;
};

interface ContextMenuProps extends WithTranslation {
    onClose: () => void;
    onReturn?: () => void;
    initialPage?: string;
    media?: any;
    itemToEdit?: any;
    initialData?: any;
}

const ContextMenu = ({ t, onClose, onReturn = onClose, initialPage, initialData }: ContextMenuProps) => {
    const [page, setPage] = useState(initialPage || '');
    const [customTitle, setCustomTitle] = useState('');
    const userDataProp = useContext(UserContext);

    const handleLogout = () => {
        localStorage.clear();
        window.location.reload();
    };

    const handleReturn = () => {
        if (page && !initialPage) setPage('');
        else onReturn();
    };

    const sections = [
        {
            title: t('Créer un jeu'),
            isActive: page === 'CREATE',
            action: () => setPage('CREATE'),
            component: <CreateGame onReturn={handleReturn} onChangeTitle={(text) => setCustomTitle(text)} initialData={initialData} />,
        },
        {
            title: t('Importer des médias'),
            isActive: page === 'IMPORT',
            action: () => setPage('IMPORT'),
            component: <ImportMedia onReturn={handleReturn} onChangeTitle={(text) => setCustomTitle(text)} initialData={initialData} />,
        },
        {
            title: t('Mes informations personnelles'),
            isActive: page === 'ACCOUNT',
            action: () => setPage('ACCOUNT'),
            logo: UserLogo,
            component: <Account onReturn={handleReturn} />,
        }, {
            title: t('Les membres de ma galaxie'),
            isActive: page === 'GALAXY_MEMBERS',
            action: () => setPage('GALAXY_MEMBERS'),
            logo: MembersLogo,
            component: <GalaxyMembers onReturn={handleReturn} />,
        }, {
            title: t('Inviter une bonne étoile'),
            isActive: page === 'INVITE_MEMBERS',
            action: () => setPage('INVITE_MEMBERS'),
            logo: InviteLogo,
            component: <InviteMembers onReturn={handleReturn} />,
        },/* {
            title: t('Mes statistiques'),
            isActive: page === 'STATS',
            action: () => setPage('STATS'),
            logo: StatsLogo,
            component: <Statistics onReturn={handleReturn} />,
        },*/
        ...(['FAMILY', 'PATIENT'].includes(userDataProp?.user?.role ?? '') ? [{
            title: t('Supprimer mon compte'),
            action: () => { },
            logo: DeleteLogo,
        }] : []),
        ...(['THERAPIST', 'ADMIN'].includes(userDataProp?.user?.role ?? '') ? [{
            action: () => setPage('INSPIRATION'),
            isActive: page === 'INSPIRATION',
            title: t(`Mon carnet d'inspiration`),
            logo: InspiLogo,
            component: <InspiNotebook onReturn={handleReturn} />,
        }] : []),
    ];

    return (
        <NavigationProvider>
            <MenuHeader title={customTitle || sections.find((e) => e.isActive)?.title || t('Gérer mon compte')} onClose={onClose} onReturn={page === '' ? onClose : undefined} />
            <div className={style.container}>
                {['ACCOUNT', 'GALAXY_MEMBERS', 'INVITE_MEMBERS', 'STATS', 'INSPIRATION', 'IMPORT', 'CREATE'].includes(page) &&
                    sections.find((section) => section.isActive)?.component
                }
                {!page && <>
                    <Action className={style.section}>
                        <div>
                            <ProfilePicture picture={userDataProp?.user?.profilePhoto as string} />
                        </div>
                        <div className={style.description}>{`${userDataProp?.user?.firstName} ${userDataProp?.user?.lastName}`}</div>
                    </Action>
                    {sections.filter((section) => section.logo).map((section, index) => (
                        <Action key={index} className={style.section} action={section.action}>
                            <div className={style.logo}>
                                {section.logo && <section.logo />}
                            </div>
                            <div className={style.description}>{section.title}</div>
                        </Action>
                    ))}
                    <Button className={style.logOut} label={t('Déconnexion')} action={handleLogout} />
                </>}
            </div>
        </NavigationProvider>
    );
};

export default withTranslation()(ContextMenu);