import { withTranslation, WithTranslation } from 'react-i18next';
import { useState } from 'react';

import Cropper from 'react-easy-crop';

import style from './style.module.css';
import { Button } from '../Button/index';
import { getCroppedImg } from '../../utilities/cropImage';
import { Slider } from '../Slider';

interface CropProps extends WithTranslation {
    img: { url: string, blob: Blob };
    callback: (croppedImage: any) => void;
}

const Crop = ({ t, img, callback }: CropProps) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

    const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }

    const showCroppedImage = async () => {
        try {
            const croppedImage = await getCroppedImg(
                img?.blob,
                croppedAreaPixels
            );
            callback(croppedImage);
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <div>
            <div className={style.cropContainer}>
                <Cropper
                    image={img?.url}
                    crop={crop}
                    zoom={zoom}
                    aspect={1}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                />
            </div>
            <div className={style.controls}>
                <div className={style.sliderContainer}>
                    <div
                        className={style.sliderLabel}
                    >{t('Zoom')}</div>
                    <Slider
                        value={zoom}
                        min={1}
                        max={3}
                        step={0.1}
                        aria-labelledby="Zoom"
                        onChange={(e, zoom) => setZoom(zoom)}
                    />
                </div>

                <Button
                    label={t('Valider')}
                    onClick={showCroppedImage}
                    variant="contained"
                    color="primary"
                    className={style.cropButton}
                />
            </div>
        </div>
    )
}

export default withTranslation()(Crop);
