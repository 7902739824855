import { WithTranslation, withTranslation } from "react-i18next";
import { FieldError, useForm } from 'react-hook-form';

import style from './style.module.css';
import FormGroup from "../FormGroup";
import UploadInput from "../UploadInput";
import Input from "../Input/index";
import Button from "../Button/index";
import ButtonsContainer from "../ButtonsContainer";
import { ImageDetail } from "../../models/Media";
import { useEffect, useState } from "react";
import Action from "../Action";
import { useNavigation } from "../../pages/ContextMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

interface ImportPhotoProps extends WithTranslation {
    onReturn: () => void;
    onImport: (data: any) => void;
    onChangeTitle?: (title: string) => void;
    data: any;
}

interface Photo {
    title: string;
    url: string;
    capturedOn: string;
    capturedPlace: string;
    anecdote: string;
    imageDetails: ImageDetail[];
}

const ImportPhoto = ({ t, onReturn, onChangeTitle, onImport, data: toEdit }: ImportPhotoProps) => {
    const { subscribe } = useNavigation();
    const [state, setState] = useState<keyof typeof titles | ''>('');
    const {
        watch,
        register,
        setValue,
        control,
        handleSubmit,
        formState: { isValid, errors },
    } = useForm<{ photos: Photo[] }>({
        mode: 'all',
        defaultValues: {
            photos: [],
        },
    });

    type TitleKeys = 'TITLE' | 'CAPTURED_ON' | 'CAPTURED_PLACE' | 'ANECDOTE';

    const titles = {
        TITLE: t('Ajouter un titre'), // TODO: Changer en "Saisir un titre" sans éditer le titre du header
        CAPTURED_ON: t('Ajouter une date'),
        CAPTURED_PLACE: t('Ajouter un lieu'),
        ANECDOTE: t('Ajouter une légende'), // TODO: Changer en "Ajouter une légende (souvenir, anecdote…)" sans éditer le titre du header
    }

    useEffect(() => {
        if (toEdit) {
            setValue('photos', toEdit);
        }
    }, [toEdit]);

    useEffect(() => {
        const handleReturn = () => {
            if (state !== '') {
                setState('');
                return;
            }
            if (onReturn) onReturn();
        };

        if (onChangeTitle) onChangeTitle(state ? titles[state] : '');

        const unsubscribe = subscribe(handleReturn);
        return () => unsubscribe();
    }, [state]);

    const onSubmit = (data: any) => {
        onImport(data.photos);
    };

    const photos: Photo[] = watch('photos');

    return (
        <>
            <div className={style.subTitle}>{t('Ajoutez des informations pour partager et transmettre des souvenirs, des anecdotes, des connaissances…')}</div>
            <form onSubmit={handleSubmit(onSubmit)}>
                {state === '' && <>
                    {!toEdit?.[0] ? <FormGroup>
                        <UploadInput
                            autoOpen
                            multiple
                            type="IMAGE"
                            control={control}
                            register={register('photos')}
                            pathStorage="photos"
                        />
                    </FormGroup> : <div className={style.imgPreview}>
                        <img src={toEdit?.[0]?.url} alt={toEdit?.[0]?.title} />
                    </div>}

                    {!!photos.length && ['TITLE', 'CAPTURED_ON', 'CAPTURED_PLACE', 'ANECDOTE'].map((title, index) => (
                        <div key={index} className={style.spoiler}>
                            <Action action={() => setState(title as TitleKeys)}>
                                <div className={style.spoilerFlex}>
                                    {titles[title as TitleKeys]}
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </div>
                            </Action>
                        </div>
                    ))}

                    {!!photos.length && <ButtonsContainer className={style.buttonsContainer} position='CENTER'>
                        <Button className={style.fullWidth} size='LARGE' theme='SECONDARY' disabled={!isValid} action={handleSubmit(onSubmit)} label={t('Continuer')} />
                    </ButtonsContainer>}
                </>}

                {['TITLE', 'CAPTURED_ON', 'CAPTURED_PLACE', 'ANECDOTE'].map((key) => (
                    state === key && <div key={key}>
                        {photos.map((photo, index) => (
                            <FormGroup
                                key={photo.url}
                                error={errors.photos?.[index]?.[key.toLowerCase() as keyof Photo] as FieldError | undefined}
                            >
                                <div className={style.inputContainer}>
                                    <div className={style.imgPreview}>
                                        <img src={photo.url} alt={photo.title} />
                                    </div>
                                    <Input
                                        register={register(`photos.${index}.${key.toLowerCase() as 'title' | 'url' | 'capturedOn' | 'capturedPlace' | 'anecdote'}`)}
                                        type={key === 'CAPTURED_ON' ? 'date' : key === 'ANECDOTE' ? 'multiline' : 'text'}
                                        placeholder={t(titles[key as TitleKeys]) as string}
                                    />
                                </div>
                            </FormGroup>
                        ))}
                        <ButtonsContainer className={style.buttonsContainer} position='CENTER'>
                            <Button className={style.fullWidth} size='LARGE' theme='SECONDARY' disabled={!isValid} action={() => setState('')} label={t('Valider')} />
                        </ButtonsContainer>
                    </div>
                ))}
            </form>
        </>
    );
}

export default withTranslation()(ImportPhoto);