import { withTranslation, WithTranslation } from 'react-i18next';
import style from './style.module.css';
import { useContext, useEffect, useState } from 'react';
import { useNavigation } from '../../pages/ContextMenu';
import { UserContext } from '../../providers/UserProvider';
import { User } from '../../models/User';
import { useForm } from 'react-hook-form';
import { getBeneficiaryMembers, getFamilyMembers } from '../../stores/User';
import { getPro } from '../../stores/Establishment';
import { PatientConvert } from '../../models/Patient';
import ButtonsContainer from '../ButtonsContainer';
import CheckMember from '../CheckMember';
import Button from '../Button/index';
import Checkbox from '../Checkbox/index';
import Action from '../Action';
import Stars from './assets/stars.svg?react';

interface ShareMediaProps extends WithTranslation {
    onReturn?: () => void;
    onChangeTitle?: (title: string) => void;
    onShare: (type: string) => void;
}

const ShareMedia = ({ t, onReturn, onChangeTitle, onShare }: ShareMediaProps) => {
    const userDataProp = useContext(UserContext);
    const role = ['PATIENT', 'FAMILY'].includes(userDataProp?.user?.role ?? '') ? 'USER' : 'PRO';
    const { subscribe } = useNavigation();
    const [coWorkers, setCoWorkers] = useState<User[]>([]);
    const [galaxyMembers, setGalaxyMembers] = useState<User[]>([]);
    const [state, setState] = useState<keyof typeof titles | ''>(role === 'PRO' ? 'SHARE_PRO' : 'SHARE');
    const {
        watch,
        register,
        setValue,
        handleSubmit,
        formState: { isValid, errors, },
    } = useForm<{ familyList: string[], proList: string[], selectAll: boolean }>({
        mode: 'all',
        defaultValues: {
            familyList: [],
            proList: [],
            selectAll: false,
        },
    });

    const titles = {
        SHARE: t('Partager votre création'),
        SHARE_PRO: t('Partager votre création'),
    }

    useEffect(() => {
        const handleReturn = () => {
            if (state === 'SHARE' && role === 'PRO') {
                setState('SHARE_PRO');
                return;
            }
            if (onReturn) onReturn();
        };

        if (onChangeTitle) onChangeTitle(state ? titles[state] : '');

        const unsubscribe = subscribe(handleReturn);
        return () => unsubscribe();
    }, [state]);

    useEffect(() => {
        const fetchMembers = async () => {
            if (state === 'SHARE') {
                if (role === 'USER') {
                    const familyMembers = await getFamilyMembers(userDataProp.token ?? '');
                    setGalaxyMembers(familyMembers);
                } else if (role === 'PRO') {
                    const beneficiaries = await getBeneficiaryMembers(userDataProp.token ?? '');
                    setGalaxyMembers(beneficiaries);
                }
            } else if (state === 'SHARE_PRO') {
                const coWorkers = await getPro(userDataProp.token ?? '');
                setCoWorkers(coWorkers?.data?.map(PatientConvert) ?? []);
            }
        };

        setGalaxyMembers([]);
        if (userDataProp.token) {
            fetchMembers();
        }
    }, [state, userDataProp.token]);


    const onSubmit = (data: any) => {
        onShare(data);
    };

    const selectAll: boolean = watch('selectAll');

    useEffect(() => {
        if (!state.startsWith('SHARE')) return;

        const listKey = state === 'SHARE' ? 'familyList' : 'proList';
        const members = state === 'SHARE' ? galaxyMembers : coWorkers;

        setValue(listKey, selectAll ? members.map(member => member.id || '') : []);
    }, [selectAll, state, galaxyMembers, coWorkers]);

    return (
        <>
            {state === 'SHARE' && <div className={style.subTitle}>{t('Sélectionnez la ou les “Bonnes étoiles” de votre communauté avec lesquelles vous souhaitez partager ce(s) média(s)…')}</div>}
            {state === 'SHARE_PRO' && <div className={style.subTitle}>{t('Choisissez les collègues avec qui vous voulez partager le jeu que vous avez créé.')}</div>}
            <div className={style.galaxyMembers}>
                <Action action={() => setValue("selectAll", !selectAll)} className={style.selectAll}>
                    <Stars className={style.stars} />
                    <div className={style.selectAllText}>{t('Partager avec tout le monde')}</div>
                    <div className={style.selectAllCheck}><Checkbox register={register('selectAll')} /></div>
                </Action>
                {(state === 'SHARE_PRO' ? coWorkers : galaxyMembers)?.map((member) => (
                    <CheckMember
                        key={member.id}
                        member={member}
                        value={member.id}
                        register={register(state === 'SHARE_PRO' ? `proList` : `familyList`)}
                    />
                ))}
            </div>
            <ButtonsContainer className={style.buttonsContainer} position='CENTER'>
                {state === 'SHARE' && <Button className={style.fullWidth} size='MEDIUM' theme='PRIMARY' disabled={!isValid} action={handleSubmit(onSubmit)} label={t('Partager')} />}
                {state === 'SHARE_PRO' && <Button className={style.fullWidth} size='MEDIUM' theme='SECONDARY' disabled={!isValid} action={() => { setState('SHARE'); setValue("selectAll", false) }} label={t('Continuer')} />}
            </ButtonsContainer>
        </>
    )
};

export default withTranslation()(ShareMedia);