/* eslint-disable @typescript-eslint/no-explicit-any */
export const getRadianAngle = (degreeValue: number): number => {
    return (degreeValue * Math.PI) / 180;
};

export const rotateSize = (width: number, height: number, rotation: number): any => {
    const rotRad = getRadianAngle(rotation);
    return {
        width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
        height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height)
    };
};

export const createImage = (url: string): Promise<any> =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', (error) => reject(error));
        image.setAttribute('crossOrigin', 'anonymous');
        image.src = url;
    });

export const getCroppedImg = async (imageBlob: Blob, pixelCrop: any): Promise<any> => {
    const canvas = document.createElement('canvas');
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext('2d');
    if (!ctx) {
        return null;
    }

    const image = new Image();
    const url = URL.createObjectURL(imageBlob);

    return new Promise((resolve, reject) => {
        image.onload = () => {
            ctx.drawImage(
                image,
                pixelCrop.x,
                pixelCrop.y,
                pixelCrop.width,
                pixelCrop.height,
                0,
                0,
                pixelCrop.width,
                pixelCrop.height
            );
            URL.revokeObjectURL(url);

            // As Base64 string
            // resolve(canvas.toDataURL('image/jpeg'));

            // As a blob
            canvas.toBlob((file) => {
                if (file) {
                    resolve(file);
                } else {
                    reject(new Error('Canvas is empty'));
                }
            }, 'image/jpeg');
        };

        image.onerror = (error) => {
            URL.revokeObjectURL(url);
            reject(error);
        };

        image.src = url;
    });
};
