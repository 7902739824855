import { withTranslation, WithTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import style from './style.module.css';
import { uploadImage } from '../../services/storageFunctions';
import ProfilePicture from '../ProfilePicture';
import Action from '../Action';
import { useEffect, useRef, useState } from 'react';
import Card from '../Card';
import AddMoreSvg from './assets/addMore.svg?react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTrash } from '@fortawesome/free-solid-svg-icons';
import Crop from '../Crop';
import { getImageKitUrlFrom } from '../../utilities/utils';

interface UploadInputProps extends WithTranslation {
    register: any;
    control: any;
    pathStorage: string;
    type?: 'USER_PICTURE' | 'IMAGE' | 'VIDEO' | 'FILE';
    multiple?: boolean;
    selected?: boolean;
    autoOpen?: boolean;
}

const UploadInput = ({ t, register, control, pathStorage, type = 'USER_PICTURE', multiple = false, selected = false, autoOpen = false }: UploadInputProps) => {
    const renderController = ({ field: { onChange, value } }: any) => {
        const fileType = type === 'USER_PICTURE' ? 'IMAGE' : type;
        const [toBeCropped, setToBeCropped] = useState<{ url: string; fileName: string; blob: File }[]>([]);
        const [relativePaths, setRelativePaths] = useState(value);
        const firstRender = useRef(true);

        useEffect(() => {
            if (autoOpen && relativePaths.length === 0 && firstRender.current) {
                firstRender.current = false;
                open();
            }
        }, []);

        const accept =
            fileType === 'IMAGE'
                ? {
                    accept: {
                        'image/png': ['.png'],
                        'image/bmp': ['.bmp'],
                        'image/jpeg': ['.jpeg', '.jpg'],
                        'image/gif': ['.gif'],
                        'image/tiff': ['.tif', '.tiff'],
                        'image/webp': ['.webp'],
                        'image/avif': ['.avif'],
                    },
                }
                : {};

        const addFile = async (files: File[]) => {
            if (files.length > 0) {
                const uploadedFiles = [...(multiple ? relativePaths : [])];
                for (const file of files) {
                    try {
                        const url = await uploadImage(file, pathStorage);
                        if (fileType === 'VIDEO') {
                            const thumbnail = await extractThumbnail(file);
                            const response = await fetch(thumbnail);
                            const blob = await response.blob();
                            const thumbnailFile = new File([blob], 'thumbnail.jpg', { type: 'image/jpeg' });
                            const thumnailUrl = await uploadImage(thumbnailFile, 'thumbnails');
                            uploadedFiles.push({
                                url,
                                thumbnail: thumnailUrl,
                                fileName: file.name,
                                blob: file,
                            });
                        } else if (fileType === 'IMAGE') {
                            setToBeCropped((old) => ([...old, { url, fileName: file.name, blob: file }]));
                        } else {
                            uploadedFiles.push({
                                url,
                                fileName: file.name,
                                blob: file,
                            });
                        }
                    } catch (error) {
                        console.error("Erreur lors de l'upload du media:", error);
                    }
                }
                onChange(uploadedFiles);
                setRelativePaths(uploadedFiles);
            }
        };

        const handleDelete = (index: number) => {
            const newRelativePaths = [...relativePaths];
            newRelativePaths.splice(index, 1);
            setRelativePaths(newRelativePaths);
            onChange(newRelativePaths);
        };

        const handleDeleteAll = () => {
            setRelativePaths([]);
            onChange([]);
        };

        const { isDragActive, isFileDialogActive, open, getRootProps, getInputProps } = useDropzone({
            multiple,
            noClick: true,
            ...accept,
            onDropAccepted: (files) => {
                if (files) {
                    if (!multiple) handleDeleteAll();
                    addFile(files);
                }
            },
        });

        const extractThumbnail = (file: File): Promise<string> => {
            return new Promise((resolve, reject) => {
                const video = document.createElement('video');
                video.src = URL.createObjectURL(file);
                video.load();
                video.addEventListener('loadeddata', () => {
                    video.currentTime = 0;
                });
                video.addEventListener('seeked', () => {
                    const canvas = document.createElement('canvas');
                    canvas.width = video.videoWidth;
                    canvas.height = video.videoHeight;
                    const context = canvas.getContext('2d');
                    context?.drawImage(video, 0, 0, canvas.width, canvas.height);
                    canvas.toBlob((blob) => {
                        if (blob) {
                            const url = URL.createObjectURL(blob);
                            resolve(url);
                        } else {
                            reject(new Error('Failed to create thumbnail'));
                        }
                    }, 'image/jpeg');
                });
                video.addEventListener('error', (e) => reject(e));
            });
        };

        const onCrop = async (cropped: File) => {
            const uploadedFiles = [...(multiple ? relativePaths : [])];
            try {
                const url = await uploadImage(cropped, pathStorage);
                uploadedFiles.push({
                    url,
                    fileName: cropped.name,
                    blob: cropped,
                });
            } catch (error) {
                console.error("Erreur lors de l'upload du media croppé:", error);
            }
            onChange(uploadedFiles);
            setRelativePaths(uploadedFiles);
            setToBeCropped((old) => old.slice(1));
        };

        const focused = isDragActive || isFileDialogActive;

        return (
            <div {...getRootProps({ className: `${style.container} ${focused ? style.focus : ''}` })}>
                <input {...getInputProps()} />

                {toBeCropped.length === 0 && relativePaths.length === 0 && (
                    <>
                        {type === 'USER_PICTURE' && (
                            <Action action={open}>
                                <ProfilePicture />
                            </Action>
                        )}
                        {type !== 'USER_PICTURE' &&
                            <Card margin={false} className={style.elemContainer} action={() => open()}>
                                <div className={style.elemContent}>
                                    <AddMoreSvg className={style.addMoreSvg} />
                                    <div className={style.textAddMore}>{multiple ? t('Importer des médias') : t('Importer un média')}</div>
                                </div>
                            </Card>
                        }
                    </>
                )}

                {toBeCropped.length > 0 && (
                    <Crop
                        callback={onCrop}
                        img={{
                            url: toBeCropped[0].url,
                            blob: toBeCropped[0].blob
                        }}
                    />
                )}

                {toBeCropped.length === 0 && relativePaths.length > 0 && (
                    <>
                        <div className={multiple ? style.gridContainer : ''}>
                            {type !== 'USER_PICTURE' && multiple &&
                                <Card margin={false} className={style.elemContainer} action={() => open()}>
                                    <div className={style.elemContent}>
                                        <AddMoreSvg className={style.addMoreSvg} />
                                        <div className={style.textAddMore}>{t('Importer des médias')}</div>
                                    </div>
                                </Card>
                            }
                            {relativePaths.map((relativePath: any, index: number) => (
                                <div key={relativePath?.url}>
                                    {type === 'USER_PICTURE' && (
                                        <Action action={handleDeleteAll}>
                                            <ProfilePicture picture={relativePath?.url} />
                                        </Action>
                                    )}
                                    {type !== 'USER_PICTURE' && (
                                        <div key={index} className={`${style.elemContainer} ${style[fileType]}`}>
                                            <Card padding={false} margin={false} className={style.elemContent}>
                                                {selected && <div className={style.selected}>
                                                    <FontAwesomeIcon icon={faCheck} />
                                                </div>}
                                                {fileType === 'IMAGE' && <div className={style.imgContainer}><img className={style.image} src={getImageKitUrlFrom(relativePath?.url)} /></div>}
                                                {fileType === 'VIDEO' && <video className={style.video} src={relativePath?.url} height={'100%'} controls />}
                                                <div className={style.control}>
                                                    {/* <Action action={() => open()} target="_blank">
                                                        <PenIcon className={style.controlSvg} style={{ '--color': 'white' } as CSSProperties} />
                                                    </Action> */}
                                                    <Action action={() => handleDelete(index)} className={style.controlRight}>
                                                        <FontAwesomeIcon className={style.controlSvg} icon={faTrash} color='white' />
                                                    </Action>
                                                </div>
                                            </Card>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>
        )
    };

    return <Controller name={register.name} control={control} render={renderController} />;
}

export default withTranslation()(UploadInput);