import { withTranslation, WithTranslation } from "react-i18next";

import UserBanner from './assets/user.svg?react';
import ProBanner from './assets/pro.svg?react';
import LogoUser from './assets/logo.svg?react';
import LogoPro from './assets/logo_pro.svg?react';
import style from './style.module.css';
import { useState, useEffect, useContext } from "react";
import Action from "../Action";
import { UserContext } from "../../providers/UserProvider";
import BurgerMenu from './assets/menu.svg?react';
import { useMenu } from "../../contexts/MenuContext";
import ContextMenu from "../../pages/ContextMenu";
import ProfilePicture from "../ProfilePicture";

interface HomeHeaderProps extends WithTranslation {
    withBanner?: boolean;
};

const HomeHeader = ({ t, withBanner = false }: HomeHeaderProps) => {
    const userDataProp = useContext(UserContext);
    const role = ['ADMIN', 'THERAPIST'].includes(userDataProp?.user?.role || '') ? 'pro' : 'user';
    const profilePicture = userDataProp?.user?.profilePhoto;
    const { openMenu, closeMenu } = useMenu();

    const useWindowWidth = () => {
        const [windowWidth, setWindowWidth] = useState(window.innerWidth);

        useEffect(() => {
            const handleResize = () => setWindowWidth(window.innerWidth);
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }, []);

        return windowWidth;
    };
    const windowWidth = useWindowWidth();

    const BannerDesktop = role === 'pro' ? ProBanner : UserBanner;

    const Banner = BannerDesktop;
    const Logo = role === 'pro' ? LogoPro : LogoUser;

    const displayMenu = () => {
        if (openMenu && closeMenu) {
            openMenu({
                component: () => <ContextMenu onClose={() => closeMenu()} />,
            });
        }
    }

    const menu = (
        <>
            <Action action={'/home'} className={style.logoContainer}>
                <Logo className={style.logo} style={{ '--fill-color': withBanner ? '#FFF' : '#0D224B' } as React.CSSProperties} />
            </Action>
            {windowWidth < 768 ? <Action className={style.burgerMenuContainer} action={() => displayMenu()}>
                <BurgerMenu className={style.burgerMenu} style={{ '--fill-color': withBanner ? '#FFF' : '#0D224B' } as React.CSSProperties} />
            </Action>
                : <Action className={style.profileContainer} action={() => displayMenu()}>
                    <ProfilePicture picture={profilePicture as string} initials={(userDataProp?.user?.firstName ?? "").charAt(0).toUpperCase() + (userDataProp?.user?.lastName ?? "").charAt(0).toUpperCase()} />
                </Action>
            }
        </>
    )

    if (!withBanner) {
        return (
            <div className={style.header}>
                <div className={style.safeSpace} />
                {menu}
            </div>
        );
    }

    return (
        <header className={style.header}>
            {menu}
            <Banner className={style.banner} />
        </header>
    );
}

export default withTranslation()(HomeHeader);