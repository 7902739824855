import { WithTranslation, withTranslation } from 'react-i18next';
import MediaCard, { MediaCardProps } from '../MediaCard';
import style from './style.module.css';
import Action from '../Action';
import multimedia from './assets/multimedia.svg';
import photo from './assets/photo.svg';
import video from './assets/video.svg';
import youtube from './assets/youtube.svg';
import createGame from './assets/createGame.svg';
import importMedia from './assets/importMedia.svg';
import { useMenu } from "../../contexts/MenuContext";
import ContextMenu from "../../pages/ContextMenu";

import { Button } from '../Button/index';

interface MediaGridProps extends WithTranslation {
    items: MediaCardProps[];
    withAlbum?: boolean;
    withImport?: boolean;
    withCreateGame?: string;
    onDisplay: (id: string) => void;
    onEdit: (id: string) => void;
    onDelete: (id: string) => void;
    type?: string;
}

const MediaGrid = ({ t, items, withAlbum, withCreateGame, withImport, onDisplay, onEdit, onDelete, type }: MediaGridProps) => {
    const { openMenu, closeMenu } = useMenu();
    const albumImg = type === 'ALL' ? multimedia : type === 'IMAGE' ? photo : type === 'VIDEO' ? video : youtube;
    const albumTitle = type === 'ALL' ? 'multimédia' : type === 'IMAGE' ? 'photos' : type === 'VIDEO' ? 'vidéos' : 'Youtube';
    const ctaType = type === 'ALL' ? 'un média' : type === 'IMAGE' ? 'une photo' : type === 'VIDEO' ? 'une vidéo' : 'une vidéo YouTube';

    const handleMenu = (initialPage: string, initialData: any) => {
        if (openMenu && closeMenu) {
            openMenu({
                component: () => <ContextMenu
                    initialPage={initialPage}
                    initialData={{
                        type: initialData
                    }}
                    onClose={() => closeMenu()}
                />,
            });
        }
    };

    const initialType = type === 'ALL' ? '' : type === 'IMAGE' ? 'IMAGE' : type === 'VIDEO' ? 'VIDEO' : 'YOUTUBE';

    return (
        <div className={style.root}>
            {withImport && <Action action={() => handleMenu('IMPORT', initialType)} className={style.album} style={{ backgroundImage: `url(${importMedia})` }}>
                <span className={[style.ctaTitle, style.white].join(' ')}>{t('Importer {{ctaType}}', { ctaType })}</span>
            </Action>}
            {withCreateGame && <Action action={() => handleMenu('CREATE', withCreateGame)} className={style.album} style={{ backgroundImage: `url(${createGame})` }}>
                <span className={[style.ctaTitle, style.white].join(' ')}>{t('Créer un jeu')}</span>
            </Action>}
            {withAlbum && (
                <Action action={() => onDisplay(items[0].id)} className={style.album} style={{ backgroundImage: `url(${albumImg})` }}>
                    <span className={[style.albumTitle, style.white].join(' ')}>{t('Diaporama {{type}}', { type: albumTitle })}</span>
                    <Button className={style.albumButton} label={t('Lancer')} />
                </Action>
            )}
            {items.map((item) => (
                <MediaCard key={item.id} {...item} action={() => onDisplay(item.id)} onEdit={() => onEdit(item.id)} onDelete={() => onDelete(item.id)} />
            ))}
        </div>
    );
};

export default withTranslation()(MediaGrid);
