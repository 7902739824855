import { withTranslation, WithTranslation } from "react-i18next";

import style from "./style.module.css";
import EinsteinSpeaking from "../EinsteinSpeaking";
import TabBar from "../TabBar";
import NavBar from "../NavBar";
import ArrowsSpin from "./assets/arrowsSpin.svg?react";
import Brain from "./assets/brain.svg?react";
import Calculator from "./assets/calculator.svg?react";
import Gears from "./assets/gears.svg?react";
import Glasses from "./assets/glasses.svg?react";
import Hand from "./assets/hand.svg?react";
import Lips from "./assets/lips.svg?react";
import GameTypeGrid from "../GameTypeGrid";
import { melimots, tourbillon, pingpong, memory, sudok, puzzle, reordon, quizz } from "../../models/Games";
import HomeHeader from "../HomeHeader";
import { useContext, useState } from "react";
import { UserContext } from "../../providers/UserProvider";
import ColoramotSvg from '../../assets/coloramot.svg?react';
import ColoramotIllustration from '../../assets/coloramotIllustration.svg?react';
import IdentriSvg from '../../assets/identri.svg?react';
import IdentriIllustration from '../../assets/identriIllustration.svg?react';
import SonoSvg from '../../assets/sono.svg?react';
import SonoIllustration from '../../assets/sonoIllustration.svg?react';
import Action from "../Action";

interface GamesComponentProps extends WithTranslation {
};

const GamesComponent = ({ t }: GamesComponentProps) => {
    const [filter, setFilter] = useState<string>('ALL');
    const userDataProp = useContext(UserContext);

    const gameTypeItems = [
        { titre: melimots.name, theme: ['MEMORY', 'LANGUAGE'], action: '/melimots/b600ba4f-0210-4753-bbf8-4ef661291310/lvl', Illustration: melimots.illustration, description: melimots.description, Logo: melimots.icon },
        { titre: tourbillon.name, theme: ['MEMORY', 'CONCENTRATION'], action: '/tourbillon/a0708502-d0c2-4364-ae27-0bff23ca8770/lvl', Illustration: tourbillon.illustration, description: tourbillon.description, Logo: tourbillon.icon },
        { titre: quizz.name, theme: ['MEMORY', 'PROCESSING_SPEED'], action: '/quizz/theme', Illustration: quizz.illustration, description: quizz.description, Logo: quizz.icon },
        { titre: memory.name, theme: ['MEMORY', 'CONCENTRATION'], action: '/memory/theme', Illustration: memory.illustration, description: memory.description, Logo: memory.icon },
        { titre: puzzle.name, theme: ['LOGIC'], action: '/puzzle/theme', Illustration: puzzle.illustration, description: puzzle.description, Logo: puzzle.icon },
        { titre: reordon.name, theme: ['MEMORY'], action: '/reordon/theme', Illustration: reordon.illustration, description: reordon.description, Logo: reordon.icon },
        { titre: pingpong.name, theme: ['HAND_EYE_COORDINATION', 'PROCESSING_SPEED'], action: '/pingpong/f7ab9cf1-9d7b-4260-9b47-54bd35b5826d/lvl', Illustration: pingpong.illustration, description: pingpong.description, Logo: pingpong.icon },
        { titre: sudok.name, theme: ['LOGIC', 'MENTAL_CALCULATION'], action: '/sudok/3e18b1d5-b8ff-4c0d-b8a0-ced424a17bfc/lvl', Illustration: sudok.illustration, description: sudok.description, Logo: sudok.icon },
    ]
        // Filter items by theme
        .filter((item) => filter === 'ALL' || item.theme.includes(filter))
        // Sort items alphabetically
        .sort((a, b) => a.titre.localeCompare(b.titre))

    // gameTypeItems.push(
    //     { titre: 'Sono', theme: [''], action: '', Illustration: SonoIllustration, description: t('Retrouvez ce qui se cache derrière ce son'), Logo: SonoSvg, disabled: true },
    //     { titre: 'Coloramot', theme: [''], action: '', Illustration: ColoramotIllustration, description: t('Associez la bonne couleur au bon mot'), Logo: ColoramotSvg, disabled: true },
    //     { titre: 'Identri', theme: [''], action: '', Illustration: IdentriIllustration, description: t('Retrouvez les intrus le plus vite possible'), Logo: IdentriSvg, disabled: true },
    // );

    const tabBarItems = [
        { label: t('Tous les jeux'), value: 'ALL', active: filter === 'ALL' },
        { label: t('Mémoire'), icon: Brain, value: 'MEMORY', active: filter === 'MEMORY' },
        { label: t('Coordination main-œil'), icon: Hand, value: 'HAND_EYE_COORDINATION', active: filter === 'HAND_EYE_COORDINATION' },
        { label: t('Concentration'), icon: Glasses, value: 'CONCENTRATION', active: filter === 'CONCENTRATION' },
        { label: t('Vitesse de traitement'), icon: ArrowsSpin, value: 'PROCESSING_SPEED', active: filter === 'PROCESSING_SPEED' },
        { label: t('Logique'), icon: Gears, value: 'LOGIC', active: filter === 'LOGIC' },
        { label: t('Langage et vocabulaire'), icon: Lips, value: 'LANGUAGE', active: filter === 'LANGUAGE' },
        { label: t('Calcul mental'), icon: Calculator, value: 'MENTAL_CALCULATION', active: filter === 'MENTAL_CALCULATION' },
    ];

    // Separate "All games" from other items
    const allGamesItem = tabBarItems.shift();
    // Sort other TabBar items alphabetically
    const sortedTabBarItems = tabBarItems.sort((a, b) => a.label.localeCompare(b.label));
    // Reinsert "All games" in first position
    if (allGamesItem) {
        sortedTabBarItems.unshift(allGamesItem);
    }


    return (
        <div className={style.root}>
            <HomeHeader />
            <EinsteinSpeaking icon='einstein'>
                <span>{t('Choisissez un type de jeu', { firstName: userDataProp?.user?.firstName })}</span>
            </EinsteinSpeaking>
            <TabBar
                onSelected={(value) => setFilter(value)}
                items={sortedTabBarItems}
            />
            <GameTypeGrid
                items={gameTypeItems}
            />
            <NavBar />
        </div>
    );
};

export default withTranslation()(GamesComponent);