import { WithTranslation, withTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useContext } from 'react';
import { UserContext } from '../../providers/UserProvider';
import { saveUser } from '../../stores/User';
import Button from '../../components/Button/index';
import ButtonsContainer from '../../components/ButtonsContainer';
import FormGrid from '../../components/FormGrid';

import FormGroup from '../../components/FormGroup';
import Input from '../../components/Input/index';
import Heading from '../../components/Heading';

interface AccountProps extends WithTranslation {
    callback?: () => void;
}

const Account = ({ t, callback }: AccountProps) => {
    const userDataProp = useContext(UserContext);
    const {
        watch,
        register,
        control,
        handleSubmit,
        setValue,
        formState: { isValid, errors },
    } = useForm({
        mode: 'all',
        defaultValues: {
            picture: userDataProp?.user?.profilePhoto ? [{ url: userDataProp?.user?.profilePhoto || '' }] : [],
            firstName: userDataProp?.user?.firstName || '',
            lastName: userDataProp?.user?.lastName || '',
            dob: userDataProp?.user?.dob?.toString().split('T')[0] || '',
            familyCode: userDataProp?.user?.familyCode || '',
            establishmentName: userDataProp?.user?.establishmentName || '',
            emailId: userDataProp?.user?.emailId || '',
        },
    });

    const onSubmit = async (data: any) => {
        const res = await saveUser(data, userDataProp?.token as string);
        if (res && userDataProp.updateUser) {
            userDataProp.updateUser({ ...userDataProp, user: res });
        }
        if (callback) callback();
    };

    const required = t('Champ obligatoire.');

    return (
        <>
            <Heading center>Oups, des informations obligatoires sont manquantes ou invalides</Heading>

            <form onSubmit={handleSubmit(onSubmit)}>
                <FormGrid>
                    <FormGroup label={t('Prénom') as string} error={errors.firstName} required>
                        <Input register={register('firstName', { required })} />
                    </FormGroup>
                    <FormGroup label={t('Nom') as string} error={errors.lastName} required>
                        <Input register={register('lastName', { required })} />
                    </FormGroup>
                </FormGrid>

                <FormGroup label={t('Date de naissance') as string} error={errors.dob} required>
                    <Input register={register('dob', { required })} type='date' />
                </FormGroup>

                <ButtonsContainer position='CENTER'>
                    <Button theme='SECONDARY' disabled={!isValid} action={handleSubmit(onSubmit)} label={t('Enregistrer les modifications')} />
                </ButtonsContainer>
            </form>
        </>
    );
};

export default withTranslation()(Account);