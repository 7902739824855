import React, { useContext, useEffect } from 'react';

import Home from '../components/Home';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import { UserContext } from '../providers/UserProvider';
import { useWindow } from '../contexts/WindowContext';
import Account from '../windows/Account';

const ModalAccount = ({ onClose }: { onClose: () => void }) => {
    return (
        <Account callback={onClose} />
    );
};

const NewHome: React.FC = () => {
    const userDataProp = useContext(UserContext);
    const { openWindow, closeWindow } = useWindow();

    const firstConnection = userDataProp?.user !== null && (!userDataProp?.user?.firstName ||
        !userDataProp?.user?.lastName ||
        userDataProp?.user?.firstName === '' ||
        userDataProp?.user?.lastName === '');

    useEffect(() => {
        try {
            ScreenOrientation.unlock();
        } catch (error) {
            console.error(error);
        }

        if (firstConnection) {
            if (openWindow && closeWindow) {
                openWindow({
                    component: () => <ModalAccount
                        onClose={() => {
                            closeWindow();
                        }}
                    />,
                });
            }
        }
    }, []);

    return (
        <Home />
    );
};

export default NewHome;